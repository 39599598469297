<template>
  <section id="one-on-one" class="route">
    <h1 class="title">
      One On
      <hr class="divider divider--vertical" />
      <span class="accent--text">One</span>
      <i class="fas fa-arrow-right"></i>
      <hr class="divider divider--vertical" />
      <span class="accent--text">{{ subRouteName }}</span>
    </h1>

    <div class="route-content" v-if="$route.name === 'OneOnOne'">
      <p class="list-item list-item--label">
        <span>
          Create a new request&nbsp;
          <router-link :to="{ name: 'CreateOneOnOne' }">here</router-link>
        </span>
      </p>
      <p class="list-item list-item--label">
        <span>
          View your requests&nbsp;
          <router-link :to="{ name: 'ListOneOnOne' }">here</router-link>
        </span>
      </p>
    </div>

    <router-view class="route-content" v-else></router-view>

    <modal-component
      :title="modalTitle"
      :visible="showModal"
      @close="toggleModal"
    >
      <p>These is a one on one details</p>
    </modal-component>
  </section>
</template>

<script>
import PermissionsMixin from "../components/mixins/permissions.mixin";
import SubrouteManagerMixin from "../components/mixins/subroute-manager.mixin";
import ModalComponent from "../components/ModalComponent.vue";

export default {
  name: "OneOnOne",

  mixins: [PermissionsMixin, SubrouteManagerMixin],

  components: { ModalComponent },

  data: () => ({
    requests: [],
    showModal: false
  }),

  computed: {
    modalTitle() {
      return "Scheduling Details";
    },

    subroutes() {
      const oneOnOneIcon = "fas fa-comment-dots grey-light--text";
      return [
        { name: "ListOneOnOne", icon: "fas fa-address-book grey-light--text" },
        { name: "ApproveOneOnOne", icon: oneOnOneIcon },
        { name: "CreateOneOnOne", icon: "fas fa-plus-circle success--text" }
      ];
    }
  },

  mounted() {
    this.broadcastSubroutes();
    this.loading = false;
  },

  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    }
  }
};
</script>

<style lang="scss">
#one-on-one {
  .category-description {
    @include slide-down-fade-in;
    font-size: $xs;
    padding: 0 $xxs $xs;

    > ul {
      padding-top: $xs;

      li {
        margin-left: $md;
      }
    }

    em,
    b {
      color: $primary-dark;
    }

    p {
      margin-bottom: $xs;
    }
  }

  .category-description__title {
    color: $accent;
    letter-spacing: $tiny;
    line-height: $md;
    padding: $xxs;
  }
}
</style>
